#layout-content-with-toolbar {

    #toolbar {
        padding: 0 0 0 16px;
        
        .logo {
            margin-right: 16px;

            .logo-image {
                display: block;
                background-image: url("/assets/icons/icon.png");
                background-size: cover;
                width: 32px;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                color: #FFFFFF;
                font-size: 16px;
                font-weight: 500;
                border-radius: 2px;
            }

            .logo-text {
                color: rgba(0, 0, 0, 0.87);
                margin-left: 16px;
                font-size: 16px;
            }
        }
    }
}